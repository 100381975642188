<template>
  <button
    class="flex items-center justify-center -mx-1 rounded-full cursor-default w-14 h-14 hover:bg-gray-200 focus:outline-none"
    :class="{ 'opacity-20': !isSet }"
  >
    <i class="fa-light fa-fw" :class="[icon]"></i>
  </button>
</template>

<script>
export default {
  name: 'LocationFeatureIcon',
  props: {
    icon: String,
    isSet: Boolean,
  },
}
</script>
<style scoped>
button {
  font-size: 28px;
}
</style>
