<template>
  <card-container class="card-side">
    <div class="relative w-full h-full">
      <!-- Content -->
      <div class="flex flex-col w-full h-full overflow-scroll">
        <!-- Image -->
        <div class="relative overflow-hidden rounded-t-3xl" style="padding-top: 75%">
          <img class="absolute top-0" :src="location.images.thumb" alt="Location image" />
          <img
            v-show="transitionInEnded"
            class="absolute top-0"
            :src="location.images.full"
            alt="Location image"
          />
        </div>

        <!-- Details -->
        <div class="p-5 space-y-2">
          <div class="mt-1 text-4xl font-bold text-center">{{ location.title }}</div>
          <location-features :location="location" />
          <div class="whitespace-pre-wrap text-md" style="font-size: 15px;">
            {{ location.description }}
          </div>
        </div>
      </div>

      <!-- Top navigation -->
      <div class="absolute top-0 flex w-full p-5 space-x-1 pointer-events-none">
        <button
          v-if="backButtonVisible"
          @click="$router.back()"
          class="text-white bg-gray-600 rounded-full pointer-events-auto w-11 h-11"
        >
          <i class="fa-light fa-arrow-left fa-lg"></i>
        </button>
        <button
          @click="toggleFavourite"
          class="text-white bg-gray-600 rounded-full pointer-events-auto w-11 h-11"
        >
          <i class="fa-light fa-heart fa-lg"></i>
        </button>
        <button class="text-gray-400 bg-gray-600 rounded-full pointer-events-auto w-11 h-11">
          <i class="fa-light fa-check fa-lg"></i>
        </button>
        <div class="flex justify-end flex-grow">
          <button
            @click="$router.push({ name: 'home', params: { transition: 'zoom-in' } })"
            class="text-white bg-gray-600 rounded-full pointer-events-auto w-11 h-11"
          >
            <i class="fa-light fa-xmark fa-lg"></i>
          </button>
        </div>
      </div>

      <!-- Bottom navigation -->
      <button
        @click="$router.push({ name: 'location details', params: { transition: 'spin-forward' } })"
        class="absolute text-white bg-gray-600 rounded-full w-11 h-11 bottom-5 right-5"
      >
        <i class="fa-light fa-ellipsis fa-xl"></i>
      </button>

      <!-- Preload user image, used in location details -->
      <img class="hidden" :src="location.userPhoto" alt="Profile photo" />
    </div>
  </card-container>
</template>

<script>
import { ref } from 'vue'
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'
import CardContainer from '../components/CardContainer.vue'
import LocationFeatures from '@/components/LocationFeatures.vue'

export default {
  components: {
    CardContainer,
    LocationFeatures,
  },
  props: { transitionInEnded: Boolean },
  beforeRouteEnter(_to, from, next) {
    next(vm => {
      if (from.name != null && from.name !== 'home' && from.name !== 'location details')
        vm.backButtonVisible = true
    })
  },
  setup() {
    const store = useStore()
    const route = useRoute()
    const backButtonVisible = ref(false)
    const location = store.getters.locationById(route.params.id)

    const toggleFavourite = () => {
      console.log('Add to favourites', location.id)
      store.dispatch('addToFavourites', location).then(() => {
        console.log('done')
      })
    }

    return {
      location,
      toggleFavourite,
      backButtonVisible,
    }
  },
}
</script>
