<template>
  <div class="relative flex justify-center">
    <div class="flex" @mouseleave="hideTooltip">
      <location-feature-icon
        icon="fa-campground"
        :is-set="featureIsSet('tent')"
        @mouseenter="showTooltip('Perfekt for telting')"
      />
      <location-feature-icon
        icon="fa-banana"
        :is-set="featureIsSet('hammock')"
        @mouseenter="showTooltip('Perfekt for hengekøye')"
      />
      <location-feature-icon
        icon="fa-person-swimming"
        :is-set="featureIsSet('swimming')"
        @mouseenter="showTooltip('Kort avstand til badeplass')"
      />
      <location-feature-icon
        icon="fa-droplet"
        :is-set="featureIsSet('freshwater')"
        @mouseenter="showTooltip('Kort avstand til drikkevann')"
      />
      <location-feature-icon
        icon="fa-fish"
        :is-set="featureIsSet('fishing')"
        @mouseenter="showTooltip('Kort avstand til fiskeplass')"
      />
      <location-feature-icon
        icon="fa-car-side"
        :is-set="featureIsSet('parking')"
        @mouseenter="showTooltip(' Kort avstand til vei/parkering')"
      />
    </div>
    <transition name="slide-top-sm">
      <div
        v-show="tooltipVisible"
        class="absolute px-4 py-1 text-sm text-gray-200 bg-gray-600 rounded-full pointer-events-none -top-8 whitespace-nowrap"
      >
        {{ tooltip }}
      </div>
    </transition>
  </div>
</template>

<script>
import { ref } from 'vue'
import LocationFeatureIcon from '@/components/LocationFeatureIcon.vue'
export default {
  name: 'LocationFeatures',
  props: {
    location: Object,
  },
  components: {
    LocationFeatureIcon,
  },
  setup(props) {
    const tooltip = ref('')
    const tooltipVisible = ref(false)

    const featureIsSet = feature => {
      const i = props.location.features.indexOf(feature)
      return i === -1 ? false : true
    }

    const showTooltip = tip => {
      tooltip.value = tip
      tooltipVisible.value = true
    }

    const hideTooltip = () => {
      tooltipVisible.value = false
    }

    return {
      tooltip,
      tooltipVisible,
      showTooltip,
      hideTooltip,
      featureIsSet,
    }
  },
}
</script>
